.teamCard__image{
    margin: 10px 0px ;
    width: 100%;
}
.teamCard__content{
    text-align: left;
}
.teamCard__Name{
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 170%;
/* or 31px */

display: flex;
align-items: center;

color: #191919;
}
.teamCard__designation{
    
font-family: Open Sans;
font-style: normal;

font-size: 14px;
line-height: 170%;
/* or 31px */

display: flex;
align-items: center;

color: #191919;
}