.slider{
    /* margin-top: 500px !important;
    background-image: url('../../assets/slide-bg.png');
    padding: 200px;
    margin-top: -230px;
    background-repeat: no-repeat;
    background-size: cover;*/
    background-color: #FAFAFA;
    padding-bottom: 120px;
    clip-path: polygon(0 5vw,100% 0,100% -moz-calc(100% - 5vw),0 100%);
    clip-path: polygon(0 5vw,100% 0,100% calc(100% - 5vw),0 100%);
}


@media screen and (min-width:1450px) {
    .slider{
        padding: 200px 310px;
        margin-top: -280px;
    }
    .slider__arrows{
        /* margin-top: 150px; */
    }
    .slider__text{
        font-size: 50px;
    }

}

.slider__text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 58px;
    color: #191919;

}
.slider__author{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 145%;
/* identical to box height, or 29px */

text-align: left;
color: #191919;
}
.slider__company{
    text-align: left;
}
.slider__design{
    text-align: right;
    position: relative;
    left: 100px;
}
.slider__content{
    padding: 60px 160px 20px 50px;
    /* height: 375px; */
}
.slider__arrows{
        /* margin-left: 300px;
        margin-top:50px */
        text-align: center;
        z-index: 999;
        position: relative;
}

img.slider__companyLogo {
    max-width: 20%;
    max-height: 70px;
}