.vision{
    padding: 100px 0;
}
.vision__heading{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 50px;
line-height: 36px;
margin-bottom: 40px;
display: flex;
align-items: center;
text-align: center;
color: #191919;
}
.vision__content{
    font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 170%;
/* or 37px */

display: flex;
align-items: center;

color: #191919;
}

.vision__right{
    margin-top: -20px;
    
}
.vision__image{
    width: 70%;
}
@media screen and (min-width:1297px) {
    /* .vision__heading{
        font-size: 55px;
    }
    .vision__content{
        font-size: 28px;
    } */
    
}