.start{
    background-image: url('../../assets/getStarted.png');
    /* background-color: black; */
       width: 100%;
   /* height: 1000px; */
   padding: 50px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
    margin-top: -110px;
   z-index: 100; 
}
.start__heading{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 42px;
line-height: 67px;
color: #FFFFFF;
text-align: left;
/* margin-top: 40px; */
}
.start__content{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #FFFFFF;
  text-align: left;
  margin-top: 25px;
}
.start__left{
    margin-top: 320px;
    /* margin-left: 50px; */
}

@media screen and (max-width: 1300px) {
    .start__left{
        margin-top: 150px;
    }
  } 


.start__line{
  bottom: 70px;
  position: absolute;
  z-index: 99;
}
.line__image{
    /* width: 50%; */
  
}
.start__image{
  width: 55%;
  object-fit: contain;

    
}