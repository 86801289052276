.meetCompanyCard3 {
  background: #191919;
  border: 1px solid #a370db;
  box-sizing: border-box;
  box-shadow: 0px -3px 0px #a370db;
  width: 100%;
  padding-top: 100px;
  height: 250px;
  margin: 10px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
}
.meetCompanyCard3__image {
  width: 200px;
  height: 50px;
  object-fit: contain;
}
.meetCompanyCard3__text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 170%;
  /* or 31px */

  color: #ffffff;
}
