.teamManagement {
  /* background-image: url('../../assets/team-management-bg.png');
    width: 100%;
    background-position: center;
    background-size: cover; */
  padding: 250px 0;
  /* height: 100%; */
  background-repeat: no-repeat;
  text-align: center;
  background-color: #fafafa;
  -webkit-clip-path: polygon(0 5vw, 100% 0, 100% calc(100% - 5vw), 0 100%);
  clip-path: polygon(0 5vw, 100% 0, 100% calc(100% - 5vw), 0 100%);
}
.teamManagement__heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 135%;
  /* identical to box height, or 68px */

  color: #191919;
}
.teamManagement__text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 170%;
  /* or 37px */
  color: #191919;
}
.team__managementImageContainer {
  text-align: left;
  margin-left: -200px;
  margin-bottom: -200px;
}
.team__managementImage {
  width: 600px;
  position: relative;
  top: 40px;
}
