.hiring__heading{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 50px;
line-height: 135%;
/* identical to box height, or 68px */


color: #191919;

}
/* .hiring__cardLeft{
    background: #191919;
border-radius: 2px;
display: flex;
justify-content: flex-start;
align-items: center;
padding: 15px;
width: 50%;
margin: 10px auto;
} */
.hiring__cardText{
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 145%;
display: flex;
align-items: center;
color: #FFFFFF;
background-color: #191919;

margin: 0 auto;
height: 60px;
padding: 35px !important;


}

.team .hiring a{
    text-decoration: none;
}

.team .wrap{
    width: 50%;
    margin: 0 auto;
}

.hiring__cardRight1{
    width: 10%;
    background-color: #7CEBDB;
    border-radius: 2px;
    padding: 25px 15px;
    position: relative;
    left: 350px;
    bottom: 47px;
    

    
}
.hiring__cardRight2{
    width: 10%;
    background-color: #FFB200;
    border-radius: 2px;
    padding: 25px 15px;
    position: relative;
    left: 350px;
    bottom: 47px;

    
}
.hiring__cardRight3{
    width: 10%;
    background-color: #5F6AD8;
    border-radius: 2px;
    padding: 25px 15px;
    position: relative;
    left: 350px;
    bottom: 47px;

    
}
.hiring__cardTextImage{
    width: 140px;
    position: relative;
    left: 25px;
}
/* .hiring__card{
    display: flex;
    width: 60%;
} */
/* .card{
    display: flex;
    justify-content: center;
} */