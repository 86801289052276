.meetCompanies{
   /* background-image: url('../../assets/meet-companies-bg.png');
    width: 100%;
     background-position: center;
    background-size: cover;
    padding-top: 250px;
    padding-bottom: 80px;
    padding-left: 150px;
    padding-right: 150px;
    z-index: 10;
   margin-top: -65px;
   text-align: center;
   height:1100px ; */
    
}
.meetCompanies__heading{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 67px;
    margin-bottom: 70px;
    color: #FFFFFF;
    text-align: center;
}


/* .portfolio-bg{
    background-image: url('../../assets/portfolio-banner-bg.png');
    background-repeat: no-repeat;
    background-size: 75%;
    padding-top: 290px;
    background-position: 100% -135%;
    position: relative;
    z-index: 999;
}

.portfolio-wrap{
    background-color: #191919;
    -webkit-clip-path: polygon(0 5vw,100% 0,100% calc(100% - 0vw),0 100%);
    clip-path: polygon(0 5vw,100% 0,100% calc(100% - 0vw),0 100%);
    padding: 55px 0 100px 0;

} */



.portfolio-bg{
    background-image: url('../../assets/porfolio-new.png');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 100% 0%;
    position: relative;
    padding: 250px;
}

.portfolio-wrap{
    background-color: #191919;
    -webkit-clip-path: polygon(0 5vw,100% 0,100% calc(100% - 0vw),0 100%);
    clip-path: polygon(0 5vw,100% 0,100% calc(100% - 0vw),0 100%);
    padding: 230px 0 100px 0;
    position: relative;
    top: -150px;

}
