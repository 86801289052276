.join{
    width: 100%;

    background-color: white;
    padding-bottom: 0px;
     text-align: center;
     position: relative;
    top: -150px;
    

}
.join__imageSection{
    text-align: left;
    display: flex;
   
 
}
.join__image{
   margin-top: -268px;
   width: 350px;
}
.join__heading{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 50px;
line-height: 135%;
text-align: center;
/* or 68px */
margin-top: -150px;

color: #191919;
/* margin-left: auto; */



margin-bottom: 50px

}
.join__btn{
    background: #FF673E;
border-radius: 100px;
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 145%;
/* or 26px */

padding: 12px 40px;

color: #FFFFFF;
border: none;
cursor:pointer;

}